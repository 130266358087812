*{
 font-family: Helvetica;
}
#root{
    display: flex;
    justify-content: center;
}
a{
    text-decoration: none;
    color: black;
}

.all{
    display: flex;
    align-items: center;
    justify-content: center;
}

.main{
    width: 90%;
    min-width: 100px;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 720px) {
    .main{
        width: auto;
    }
}

.textup{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./assets/hierba2.jpg");
    background-position: 85%;

}
/* .headerimg{
    content: "";
    position: absolute;
    background-image: url("\hierba2.jpg");
    opacity: 0.8;
    height: 180px;
    width: 1000px;
} */

.headerimg2{
  
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

h4{
    align-items: center;
    
}
.headbuttons{
    width: 100%;
    display: flex;
    flex-direction: row ;
    flex-wrap: wrap;
    justify-content: space-evenly;
    min-height: 50px;
    max-height: 100px;
    overflow: auto;
    background-color: rgba(165, 42, 42, 0.841);

}
.headcomp{
    font-weight: bold;
    height:50px;
    width: auto;
    font-size: large;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}
.headcomp:hover {
    background-color: rgb(165, 42, 42);
    text-decoration: underline;
}

.body{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.aside{
    flex: 10%;
    flex-shrink: 1;
}
.bodymain{
    flex: 70%;
    display:flex;
    flex-shrink: 2;
    flex-direction: column;
}

.newsaside{
    display: flex;
    flex: 200px;
    flex-wrap: wrap;
    padding: 15px;
    border-radius: 5px;
    align-items: center;
    justify-content:start;
    /* border: 1px solid rgba(137, 36, 36, 0.553); */
}
.newsaside p,h5, img{
    margin:10px; 
}
@media screen and (min-width: 700px ){
    
.newsaside:hover{
    transition: transform 500ms;
    transform: scale(1.1);
    cursor:pointer;
    padding: 5px;
    background-image: linear-gradient(to right, rgba(15, 115, 15, 0.677), rgba(27, 195, 27, 0.266))
}

.sectionppal:hover{
    background-image: linear-gradient(to right, rgb(15, 115, 15), rgba(27, 195, 27, 0.545));
    border-radius: 10px;
    cursor: pointer;
}
}

#titleaside{
    padding: 3px; 
}

.titleaside{
    background-image: linear-gradient(to right,  rgba(69, 180, 38, 0.458), rgba(4, 104, 24, 0.885) );
}

.sectionppal{
    margin: 10px;
    
}

.sectionppala{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.newsaside__texts{
    flex: 50%;
    flex-shrink: 1;
}


.ppalImgs{
    flex: 30%;
    max-width: 300px;
    height: auto;
    flex-shrink: 2;
    margin: 10px;
    border-radius: 10px;
    object-fit:cover
}

#lema{
    color: antiquewhite;
    font-weight: bold;
}

.foot{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: fit-content;
    padding: 25px;
    border-radius: 5px;
    background-image: linear-gradient(to right, rgba(66, 116, 66, 0.666), rgb(0, 146, 0));

}

.pages{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 25px;
}

.arrow:hover{
   cursor: pointer
}
.sectiontitle{
    margin: 16px;
    padding: 10px;
    font-size: larger;
    font-weight: bolder;
    font-style: italic;
    border-radius: 5px;
    border-bottom: 6px solid rgba(0, 0, 0, 0.699);
    background-image: linear-gradient(to right, rgba(4, 104, 24, 0.604), rgba(69, 180, 38, 0.458));
}

.textnews{
    flex: 60%;
    min-width: 100px;
    max-width: 100%;
}